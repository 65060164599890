import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TourFrame from "../components/TourFrame"

import BannerSalon from '../components/BannerSalon'
import BannerAgenda from '../components/BannerAgenda'
// import CardVideo from '../components/CardVideo'
import CardSalon from '../components/CardSalon'
import GaleriaLightbox from '../components/GaleriaLightbox'

import pic1 from '../images/galeria/c-1.jpg'
import pic2 from '../images/galeria/c-2.jpg'
import pic3 from '../images/galeria/c-3.jpg'
import pic4 from '../images/galeria/c-4.jpg'
import pic5 from '../images/galeria/c-5.jpg'
import pic6 from '../images/galeria/c-6.jpg'
import pic7 from '../images/galeria/c-7.jpg'
import pic8 from '../images/galeria/c-8.jpg'
import pic9 from '../images/galeria/c-9.jpg'
import pic10 from '../images/galeria/c-10.jpg'
import pic11 from '../images/galeria/c-11.jpg'
import pic12 from '../images/galeria/c-12.jpg'
import pic13 from '../images/galeria/c-13.jpg'
import pic14 from '../images/galeria/c-14.jpg'
import pic15 from '../images/galeria/c-15.jpg'
import pic16 from '../images/galeria/c-16.jpg'
import pic17 from '../images/galeria/c-17.jpg'
import pic18 from '../images/galeria/c-18.jpg'

import banner from '../videos/cataluna.mp4'
// import videoThumb1 from '../images/video-thumb-1.jpg'
// import videoThumb2 from '../images/video-thumb-2.jpg'
import salon2 from '../images/thumb-salon-jordi.jpg'
import check from '../images/i-check.svg'

const photos = [
	{ src: pic1, width: 4, height: 3 },
	{ src: pic2, width: 4, height: 3 },
	{ src: pic3, width: 4, height: 3 },
	{ src: pic4, width: 4, height: 3 },
	{ src: pic5, width: 4, height: 3 },
	{ src: pic6, width: 4, height: 3 },
	{ src: pic7, width: 4, height: 3 },
	{ src: pic8, width: 4, height: 3 },
	{ src: pic9, width: 4, height: 3 },
	{ src: pic10, width: 4, height: 3 },
	{ src: pic11, width: 4, height: 3 },
	{ src: pic12, width: 4, height: 3 },
	{ src: pic13, width: 4, height: 3 },
	{ src: pic14, width: 4, height: 3 },
	{ src: pic15, width: 4, height: 3 },
	{ src: pic16, width: 4, height: 3 },
	{ src: pic17, width: 4, height: 3 },
	{ src: pic18, width: 4, height: 3 },
]

const PlazaCataluna = () => (
	<Layout>

		<SEO title="Salón Plaza Cataluña" />

		<BannerSalon
			titulo="Plaza Cataluña"
			capacidad="Hasta 500 personas"
			bgsrc={banner}
		>
			<div className="embed-responsive embed-responsive-16by9">
				<iframe title="Plaza Cataluña" className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/48U9xZuHgXc?autoplay=1" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
			</div>
		</BannerSalon>

		<section className="info-salon-cont">
			<div className="acerca">
				<article>
					<h2>Acerca del salón</h2>
					<p>El salón Plaza Cataluña está dedicado a la plaza del mismo nombre en el centro de Barcelona, lugar que destaca por su importancia en la vida comercial y social a través de la historia de la ciudad. El salón cuenta con arquitectura y decoración europea, los colores blanco y café son los protagonistas del conjunto, lo que permite resaltar cualquier color de iluminación durante tu evento. Los candiles tienen semblanza con los palacios europeos, lo que convierte al salón en un lugar de elegancia. Los recuerdos y cariño traídos de los viajes y experiencias por Europa se ven reflejados en el Salón y se verán tambien reflejados en tu evento.</p>
				</article>
			</div>
			<div className="amenidades">
				<div className="inner">
					<h3>Amenidades</h3>
					<ul>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Acabados de lujo</h4>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Lobby Independiente</h4>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Cuarto vestidor</h4>
							<p>Para uso de los novios o las quinceñaeras.</p>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Coordinadora</h4>
							<p>Tu evento incluye la ayuda de nuestra coordinadora el día de tu evento.</p>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Accesibilidad</h4>
							<p>Acceso para personas con discapacidad.</p>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Plano del salón</h4>
							<p>Teatro circular, u-shaped, boardroom y clase.</p>
						</li>
						<li>
							<img aria-hidden="true" src={check} alt="Check" width="27" height="27"/>
							<h4>Estacionamiento gratis</h4>
						</li>
					</ul>
				</div>
			</div>
		</section>

		<TourFrame source="https://my.matterport.com/show/?m=fadccAeLDNK"></TourFrame>

		{/* <section className="videos-salon">
			<div className="heading-s1 text-center text-white mb-4">
				<h3 className="heading-titulo">Galería</h3>
				<p className="heading-caption">Vídeos de nuestro salón</p>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-5">
						<CardVideo
							buttonLabel="Boda Salón Cataluña"
							src={videoThumb1}
							youtubeid="9P6BkcZ65Rw"
							className="video-modal"
						></CardVideo>
					</div>
					<div className="col-md-6 mb-5">
						<CardVideo
							buttonLabel="Boda Salón Cataluña 2"
							src={videoThumb2}
							youtubeid="9P6BkcZ65Rw"
							className="video-modal"
						></CardVideo>
					</div>
				</div>
			</div>
		</section> */}

		<section className="galeria-fotos">
			<div className="heading-s1 text-center mb-4">
				<h3 className="heading-titulo">Galería</h3>
				<p className="heading-caption">Fotografías</p>
			</div>
			<div className="container">
				<GaleriaLightbox photos={photos}></GaleriaLightbox>
			</div>
		</section>

		<section className="interes-extra">
			<div className="heading-s1 text-center mb-4">
				<p className="heading-caption">También te puede interesar</p>
			</div>
			<div className="container">
				<div className="row">
					<div className="col-md-6 mb-5">
						<CardSalon
							titulo="Salón Saint Jordi"
							subtitulo="Capacidad: 300 personas"
							imgsrc={salon2}
							link="/saint-jordi"
						/>
					</div>
					<div className="col-md-6 mb-5">
						<div className="descargas pl-5">
							<small>Barcelona Catering</small>
							<p>Conoce el menú que tenemos para tu evento</p>
							<Link to="/catering" className="btn btn-outline">Ir a catering</Link>
						</div>
					</div>
				</div>
			</div>
		</section>

		<BannerAgenda paddingtop="pt-0"/>

	</Layout>
)

export default PlazaCataluna
